import { cloneElement, useState } from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

import InputTags from './InputTags.component'; // Asegúrate de que la ruta sea correcta

import { useDisclosure } from '@/hooks/useDisclosure';
import { createFaq, AddQuestion, getFaqs } from '@/request/hubbot-api';
import { categories, chatbot } from '@/signals';

interface AddQuestionModalProps {
  button: React.ReactElement;
}

export const AddQuestionModal = ({ button }: AddQuestionModalProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const defaultQuestion = {
    question: '',
    response: '',
    category: '',
    source: '',
    tags: [],
    action: '',
  };
  const [question, setQuestion] = useState<AddQuestion>(defaultQuestion);
  const trimmedCategory = question.category.trim();
  const trimmedQuestion = question.question.trim();
  const trimmedAnswer = question.response.trim();

  const handleAddQuestion = async () => {
    try {
      onClose();
      void (await createFaq(question));
      await getFaqs();
    } catch (e) {
      console.error(e);
    } finally {
      setQuestion(defaultQuestion);
    }
  };

  const handleInputChange = (key: string, value: string | string[]) => {
    const newQuestion = {
      ...question,
      [key]: value,
    };

    setQuestion(newQuestion);
  };
  const clonedButton = cloneElement(button, { onClick: onOpen });

  const hasAction =
    chatbot.value !== undefined &&
    chatbot.value !== null &&
    chatbot.value.actions !== undefined &&
    chatbot.value.actions.length > 0;

  return (
    <>
      {clonedButton}

      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="flex-center">
          <div className="flex items-center gap-2 font-bold	">
            Agregar pregunta
          </div>
        </DialogTitle>
        <DialogContent className="w-[488px] h-[428px]">
          <div className="mb-4 mt-2">
            <Autocomplete
              fullWidth
              freeSolo
              value={question?.category}
              options={categories.value}
              size="small"
              onChange={(event, value) => {
                handleInputChange('category', value || '');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="category"
                  label="Categoría"
                  onChange={(event) => {
                    const { name, value } = event.target;
                    const normalizedValue =
                      typeof value === 'string'
                        ? value.replace(/,/g, '')
                        : value;

                    handleInputChange(name, normalizedValue);
                  }}
                />
              )}
            />
          </div>
          <div className="mb-4">
            <TextField
              name="question"
              fullWidth
              label="Pregunta"
              size="small"
              value={question?.question}
              onChange={(event) => {
                const { name, value } = event.target;

                handleInputChange(name, value);
              }}
            />
          </div>
          <div className="mb-4">
            <TextField
              name="response"
              fullWidth
              label="Respuesta"
              multiline
              size="small"
              value={question?.response}
              onChange={(event) => {
                const { name, value } = event.target;

                handleInputChange(name, value);
              }}
              inputProps={{ maxLength: 2000, style: { maxHeight: '256px' } }}
            />
          </div>
          <div className="mb-4">
            <InputTags
              tags={question.tags || []}
              onChange={(newTags) => {
                handleInputChange('tags', newTags);
              }}
              options={categories.value}
            />
          </div>
          <div className="mb-4">
            <TextField
              name="source"
              fullWidth
              label="Fuente"
              size="small"
              value={question?.source}
              onChange={(event) => {
                const { name, value } = event.target;

                handleInputChange(name, value);
              }}
            />
          </div>
          <div className="mb-4">
            {hasAction && (
              <Autocomplete
                fullWidth
                freeSolo
                value={question?.action || ''}
                options={chatbot.value?.actions || []}
                size="small"
                onChange={(event, value) => {
                  handleInputChange('action', value || '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="action"
                    label="Acción"
                    onChange={(event) => {
                      const { name, value } = event.target;

                      handleInputChange(name, value);
                    }}
                  />
                )}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions className="!justify-center">
          <Button
            disabled={!trimmedCategory || !trimmedQuestion || !trimmedAnswer}
            onClick={() => {
              void handleAddQuestion();
            }}
            variant="contained"
            color="primary"
          >
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
